import React, {useEffect, useState} from 'react';
import {post} from "../utils/http";
import storage from "../utils/storage";
import i18next from "i18next";
import ReactTooltip from "react-tooltip";
import {redirectLogin} from "../utils/func";
import config from '../config.json';
import {useHistory} from "react-router-dom";
export const ProfileContext = React.createContext();


export function ProfileProvider (props) {
    const history = useHistory();
    const [value, setValue] = useState({
        email:null,
        company:{
            id:null,
            name:null,
            lang:null
        }
    });

    function getData(){


        async function fetchData() {
            try{
                const response = await post('/auth/check');
                setValue({
                    ...response,
                });
                if(storage.get("language")!=response?.lang){
                    storage.set("language",response.lang);
                    i18next.changeLanguage(response.lang);

                }

                if(storage.get("redirect_url",false)!==false){
                    window.location = (storage.get("redirect_url").replace(window.location.origin,""));
                    storage.remove("redirect_url")
                }

            }catch (e) {
                if(!window.location.pathname.includes('auth')){
                    storage.set("redirect_url",window.location.href);
                }

                redirectLogin();
            }




        }
        fetchData()
    }

    useEffect (() => {

        try{
            getData();

        }catch (e) {

        }
    }, []);


    const dispatch = () => {
        getData();
    };

    return (
        <ProfileContext.Provider value={{
            value,
            dispatch
        }}>
            {props.children}
        </ProfileContext.Provider>
    )
}
