import React, {useContext, useEffect, useState} from 'react';
import AuthHOC from "../HOC/Auth";
import {Link, withRouter} from "react-router-dom";
import {useLogin} from "../../hooks/useAuth";
import i18next from "i18next";
import Auth from "../layouts/auth";
import Address from "../layouts/address";
import {AddressContext} from "../../contexts/AddressContext";
import AsyncSelect from 'react-select/async'
import Select from 'react-select/'
import {selectStyles} from "../../utils/func";
import {useAddress} from "../../hooks/useAddress";
import Stage3 from "./stage3";
const onNumberOnlyChange = (event: any) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const isValid = new RegExp("[0-9]").test(keyValue);
    if (!isValid) {
       event.preventDefault();
       return;
    }
};

const AddressForm = ()=> {
    const addressContext = useContext(AddressContext);
    const {countryList,cityList,districtList,neighborhoodList,
        setCountry,setCity,setDistrict,setNeighborhood,
        country,city,district,neighborhood} = useAddress();

    const [selectCountry,setSelectCountry] = useState(null);
    const [selectCity,setSelectCity] = useState(null);
    const [selectDistrict,setSelectDistrict] = useState(null);
    const [selectNeighborhood,setSelectNeighborhood] = useState(null);

    useEffect(()=>{
        let countryLabel = countryList.find((i)=>{
            if(i.value===addressContext.data.stage_3_1.country){
                return i.label;
            }
        });
        setSelectCountry(countryLabel);
        setCountry(countryLabel?.value);
    },[countryList]);

    useEffect(()=>{
        let cityLabel = cityList.find((i)=>{
            if(i.value===addressContext.data.stage_3_1.city){
                return i.label;
            }
        });
        console.log(cityList);
        setSelectCity(cityLabel);
        setCity(cityLabel?.value);
    },[cityList]);

    useEffect(()=>{
        let districtLabel = districtList.find((i)=>{
            if(i.value===addressContext.data.stage_3_1.district){
                return i.label;
            }
        });
        setSelectDistrict(districtLabel)
        setDistrict(districtLabel?.value);
    },[districtList]);

    useEffect(()=>{
        let neighborhoodLabel = neighborhoodList.find((i)=>{
            if(i.value===addressContext.data.stage_3_1.neighborhood){
                return i.label;
            }
        });
        setSelectNeighborhood(neighborhoodLabel)
        setNeighborhood(neighborhoodLabel?.value);
    },[neighborhoodList]);
  
    return (
        <form className="address-form"  onSubmit={(e)=>{
            e.preventDefault();
            if(addressContext.validate("3_1")) {
                addressContext.dispatch({active_page: 4, current_page: 4});
            }
        }}>
            {/* Form Group */}
            <div className="cropid-form-group flex two">
                {/* Select */}
                <div className={"cropid-select-group "+(addressContext.data.stage_3_1.validations?.includes("country") ? 'error' : '')}>
                    <Select className={"react_select"} styles={selectStyles()} placeholder={"Ülke"} onChange={({value})=>{
                        setCountry(value)
                        addressContext.dispatch({stage_3_1:{...addressContext.data.stage_3_1,country:value}});
                    }} options={countryList} value={selectCountry} />
                </div>
                {/* Select */}
                <div className={"cropid-select-group "+(addressContext.data.stage_3_1.validations?.includes("city") ? 'error' : '')}>
                    <Select className={"react_select"} styles={selectStyles()} placeholder={"Şehir"} options={cityList}  onChange={({value})=>{
                        setCity(value);
                        addressContext.dispatch({stage_3_1:{...addressContext.data.stage_3_1,city:value}});
                    }} value={selectCity} />
                </div>
            </div>
            {/* Form Group */}
            <div className="cropid-form-group flex two">
                {/* Select */}
                <div className={"cropid-select-group "+(addressContext.data.stage_3_1.validations?.includes("district") ? 'error' : '')}>
                    <Select className={"react_select"} styles={selectStyles()} placeholder={"İlçe"}  options={districtList}  onChange={({value})=>{
                        setDistrict(value);
                        addressContext.dispatch({stage_3_1:{...addressContext.data.stage_3_1,district:value}});
                    }} value={selectDistrict}  />
                </div>
                {/* Select */}
                <div className={"cropid-select-group "+(addressContext.data.stage_3_1.validations?.includes("neighborhood") ? 'error' : '')}>
                    <Select className={"react_select"} styles={selectStyles()} placeholder={"Mahalle"} options={neighborhoodList}  onChange={({value})=>{
                        setNeighborhood(value);
                        addressContext.dispatch({stage_3_1:{...addressContext.data.stage_3_1,neighborhood:value}});
                    }} value={selectNeighborhood}/>
                </div>
            </div>
            {/* Form Group */}
            <div className="cropid-form-group flex three">
                {/* Input Group */}
                <div className={"cropid-input-group type-2 "+(addressContext.data.stage_3_1.validations?.includes("apartment") ? 'error' : '')}>
                    {/* Input */}
                    <input id="inp1" type="text" onChange={(e)=>{
                        addressContext.dispatch({stage_3_1:{...addressContext.data.stage_3_1,apartment:e.target.value}});
                    }} value={addressContext.data.stage_3_1.apartment} className="cropid-input" placeholder="Bina No" />
                    {/* Inner Icon */}
                    <label htmlFor="inp1" className="inner--icon">
                        <i className="cropid cropid-house" aria-hidden="true" />
                    </label>
                </div>
                {/* Input Group */}
                <div className={"cropid-input-group type-2 "+(addressContext.data.stage_3_1.validations?.includes("floor") ? 'error' : '')}>
                    {/* Input */}
                    <input id="inp2" inputMode={"numeric"} pattern="[0-9]*" type="number" className="cropid-input" onChange={(e)=>{
                        addressContext.dispatch({stage_3_1:{...addressContext.data.stage_3_1,floor:e.target.value}});
                    }} value={addressContext.data.stage_3_1.floor} placeholder="Kat" />
                    {/* Inner Icon */}
                    <label htmlFor="inp2" className="inner--icon">
                        <i className="cropid cropid-step" aria-hidden="true" />
                    </label>
                </div>
                {/* Input Group */}
                <div className={"cropid-input-group type-2 "+(addressContext.data.stage_3_1.validations?.includes("rooms") ? 'error' : '')}>
                    {/* Input */}
                    <input inputMode={"numeric"} pattern="[0-9]*" id="inp3" type="number" className="cropid-input" onChange={(e)=>{
                        addressContext.dispatch({stage_3_1:{...addressContext.data.stage_3_1,rooms:e.target.value}});
                    }} value={addressContext.data.stage_3_1.rooms} placeholder="Daire No" />
                    {/* Inner Icon */}
                    <label htmlFor="inp3" className="inner--icon">
                        <i className="cropid cropid-house" aria-hidden="true" />
                    </label>
                </div>
            </div>
            {/* Form Group */}
            <div className="cropid-form-group flex two-r">
                <input id="inp2" type="text" className={"cropid-input type-2 "+(addressContext.data.stage_3_1.validations?.includes("details") ? 'error_border' : '')}  onChange={(e)=>{
                    addressContext.dispatch({stage_3_1:{...addressContext.data.stage_3_1,details:e.target.value}});
                }} style={{width:'100%'}} value={addressContext.data.stage_3_1.details} placeholder="Adres Tarifi (Sokak vb detaylar ekleyebilirsiniz.)" />
            </div>
        </form>
    );
}

const Stage3_1 = ({history,onlyForm=false}) => {

    const addressContext = useContext(AddressContext);
    const [invoiceType,setInvoiceType] = useState(addressContext.data.stage_3_1.type);
    const [sameAddress,setSameAddress] = useState(addressContext.data.stage_3_1.sameAddress);


    useEffect(()=>{
        addressContext.dispatch({stage_3_1:{...addressContext.data.stage_3_1,type:invoiceType,sameAddress:sameAddress}});
    },[invoiceType,sameAddress]);
    return (
        <> 
            {/* WIZARD PAGE CONTENT SECTION BEGIN */}
            <section id="page" className="wizard-content">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className={onlyForm ? "col-lg-12" : "col-xl-5 col-lg-6"}>
                            {/* Wizard Content */}
                            <div className="wizard-content-inner">
                                {/* Title */}
                                <div className={"title "+(onlyForm ? 'hidden' : null)}>
                                    <h6>3. Adım</h6>
                                    <h1>Adres Bilgilerini Oluştur</h1>
                                </div>
                                {/* Info */}
                                <div className={"info "+(onlyForm ? 'hidden' : null)}>
                                    <p>Gerekli adres bilgilerini doldurdunuz, sıra fatura adresinde.</p>
                                </div>
                                {/* Cropid Tab Item */}
                                <ol className="cropid-tab-items invoice-tab-items">
                                    <li className={invoiceType=="individual" ? "cropid-active" : ""}>
                                        <a href="#" onClick={(e)=>{
                                            e.preventDefault();
                                            setInvoiceType("individual");
                                        }} data-cropid-wrapper="#invoice-tab" data-cropid-tab="#bireysel">Bireysel</a>
                                    </li>
                                    <li className={invoiceType=="corporate" ? "cropid-active" : ""}>
                                        <a href="#"  onClick={(e)=>{
                                            e.preventDefault();
                                            setInvoiceType("corporate");
                                        }}  data-cropid-wrapper="#invoice-tab" data-cropid-tab="#kurumsal">Kurumsal</a>
                                    </li>
                                </ol>
                                {/* Cropid Sperator */}
                                <div className="cropid-sperator invoice-sperator" />
                                {/* Cropid Tab */}
                                <div id="invoice-tab" className="cropid-tab-wrapper invoice-tabs">
                                    <div id="bireysel" className={"cropid-tab "+(invoiceType=="individual" ? "cropid-active" : null )}>
                                        <form role="tab">
                                            {/* Input Group */}
                                            <div className={"cropid-input-group type-2 "+(addressContext.data.stage_3_1.validations?.includes("idNumber") ? 'error' : '')}>
                                                {/* Input */}
                                                <input id="name" type="tel" pattern="[0-9]+" maxLength="11" onKeyPress={onNumberOnlyChange} className="cropid-input" onChange={(e)=>{
                                                    addressContext.dispatch({stage_3_1:{...addressContext.data.stage_3_1,idNumber:e.target.value}});
                                                }} value={addressContext.data.stage_3_1.idNumber} placeholder="T.C. Kimlik No" />
                                                {/* Inner Icon */}
                                                <label htmlFor="name" className="inner--icon">
                                                    <i className="cropid cropid-user" aria-hidden="true" />
                                                </label>
                                                {/* Error Message */}
                                                <span className="error-message">Lütfen bilgileri kontrol ediniz.</span>
                                            </div>
                                        </form>
                                    </div>
                                    <div id="kurumsal" className={"cropid-tab "+(invoiceType=="corporate" ? "cropid-active" : null )}>
                                        <form role="tab">
                                            {/* Input Group */}
                                            <div className={"cropid-input-group type-2 "+(addressContext.data.stage_3_1.validations?.includes("taxNumber") ? 'error' : '')}>
                                                {/* Input */}
                                                <input id="name" type="number" className="cropid-input" onChange={(e)=>{
                                                    addressContext.dispatch({stage_3_1:{...addressContext.data.stage_3_1,taxNumber:e.target.value}});
                                                }} value={addressContext.data.stage_3_1.taxNumber} placeholder="Vergi Kimlik Numarası" />
                                                {/* Inner Icon */}
                                                <label htmlFor="name" className="inner--icon">
                                                    <i className="cropid cropid-id-thin" aria-hidden="true" />
                                                </label>
                                                {/* Error Message */}
                                                <span className="error-message">Lütfen bilgileri kontrol ediniz.</span>
                                            </div>
                                            {/* Input Group */}
                                            <div className={"cropid-input-group type-2 "+(addressContext.data.stage_3_1.validations?.includes("taxOffice") ? 'error' : '')}>
                                                {/* Input */}
                                                <input id="name" type="text" className="cropid-input" onChange={(e)=>{
                                                    addressContext.dispatch({stage_3_1:{...addressContext.data.stage_3_1,taxOffice:e.target.value}});
                                                }} value={addressContext.data.stage_3_1.taxOffice} placeholder="Vergi Dairesi" />
                                                {/* Inner Icon */}
                                                <label htmlFor="name" className="inner--icon">
                                                    <i className="cropid cropid-tax-thin" aria-hidden="true" />
                                                </label>
                                                {/* Error Message */}
                                                <span className="error-message">Lütfen bilgileri kontrol ediniz.</span>
                                            </div>
                                            {/* Checkbox Group */}
                                        </form>

                                    </div>
                                    <div className="cropid-checkbox-group type-1">
                                        {/* Input */}
                                        <input type="checkbox" id="sameAddress" className="cropid-checkbox" onClick={(e)=>{
                                            if(e.target.checked){
                                                setSameAddress(true);
                                            }else{
                                                setSameAddress(false);
                                            }
                                        }} defaultChecked={addressContext.data.stage_3_1.sameAddress} />
                                        {/* Label */}
                                        <label htmlFor="sameAddress"><span>Fatura adresim, teslim adresim ile aynı.</span></label>
                                    </div>
                                </div>
                                {!sameAddress ? <AddressForm /> : null}

                                {/* Content Footer */}
                                <div className={"content-footer "+(onlyForm ? 'hidden' : null)}>
                                    {/* Back Button */}
                                    <a href=""  onClick={(e)=>{
                                        e.preventDefault();
                                        addressContext.dispatch({active_page:3});
                                    }}  className="back-btn s">
                                        {/* Icon */}
                                        <i className="cropid cropid-arrow-left" />
                                        {/* Inner Text */}
                                        <span className="inner--text">Geri Dön</span>
                                    </a>
                                    {/* Continue Button */}
                                    <a href="" onClick={(e)=>{
                                        e.preventDefault()
                                        if(addressContext.validate("3_1")) {
                                            addressContext.dispatch({active_page: 4, current_page: 4});
                                        }
                                    }} className="cropid-button type-1 with-icon">
                                        {/* Inner Text */}
                                        <span className="inner--text">Devam Et</span>
                                        {/* Inner Icon */}
                                        <span className="inner--icon">
                <i className="cropid cropid-arrow-up-right" aria-hidden="true" />
              </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
}

export default Stage3_1