import React from "react";
import Login from "../screens/Auth/Login";
import ForgotPassword from "../screens/Auth/ForgotPassword";
import Dashboard from "../screens/Dashboard/dashboard";
import storage from "../utils/storage";
import config from '../config';
import Register from "../screens/Auth/Register";
import ResetPassword from "../screens/Auth/ResetPassword";
import Profile from "../screens/Profile";
import NewAddress from "../screens/Address/new";
import {setCookie} from "../utils/func";


const routes = [
    {
        path: "/",
        exact: true,
        main: () => <Dashboard />
    },
    {
        path: "/auth/login",
        main: () => <Login />
    },
    {
        path: "/auth/register",
        main: () => <Register />
    },
    {
        path: "/auth/logout",
        main: () => {
            storage.remove(config.token_name)
            setCookie("web_token",null,1);
            window.location='/'
        }
    },
    {
        path: "/auth/forgot-password",
        main: () => <ForgotPassword />
    },
    {
        path: "/auth/reset-password/:token",
        main: () => <ResetPassword />
    },
    {
        path: "/profile",
        main: () => <Profile />
    }
    ,
    {
        path: "/new-address",
        main: () => <NewAddress />
    }
    ,
    {
        path: "/address/:slug",
        main: () => <NewAddress />
    }
];

export default routes;
