import React, {useContext, useEffect, useState} from 'react';
import AuthHOC from "../HOC/Auth";
import {Link, withRouter} from "react-router-dom";
import {useLogin} from "../../hooks/useAuth";
import i18next from "i18next";
import Auth from "../layouts/auth";
import Address from "../layouts/address";
import {AddressContext} from "../../contexts/AddressContext";
import Stage1 from "./stage1";
import Stage2 from "./stage2";
import Stage3 from "./stage3";
import Stage4 from "./stage4";
import Stage3_1 from "./stage3.step1";

const NewAddress = ({history,match}) => {

    const addressContext = useContext(AddressContext);
    useEffect(()=>{
        if(match.params?.slug!=undefined){
            addressContext.get(match.params?.slug,(data)=>{
                document.title=data.stage_1.code+' kodlu adresi düzenliyorsunuz';
            })
        }else{
            document.title="Yeni adres"
            //addressContext.reset()
        }
    },[]);
    return (
        <Address>
            {addressContext.data.active_page==1 ? <Stage1 key={1} history={history} match={match} /> : null}
            {addressContext.data.active_page==2 ? <Stage2 key={2} history={history} match={match} /> : null}
            {addressContext.data.active_page==3 ? <Stage3 key={3} history={history} match={match} /> : null}
            {addressContext.data.active_page==3.1 ? <Stage3_1 key={31} history={history} match={match} /> : null}
            {addressContext.data.active_page==4 ? <Stage4 key={4} history={history} match={match} /> : null}
        </Address>

    );
}

export default withRouter (AuthHOC(NewAddress));
