import React, {useContext} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import routes from "./routes";
import {ProfileContext, ProfileProvider} from "./contexts/ProfileContext";
import ReactTooltip from "react-tooltip";
import {AddressProvider} from "./contexts/AddressContext";
import config from './config.json';
import storage from "./utils/storage";

function App() {
    const profileContext = useContext(ProfileContext);
  return (
        <Router>
            <Switch>
                <ProfileProvider>
                    <AddressProvider>
                            {routes.map((route, index) => {
                                if(storage.get(config.token_name,false)===false && !route.path.includes("auth")){
                                    return (<div key={index}>
                                    </div>);
                                }
                                return (
                                <Route
                                    key={index}
                                    path={route.path}
                                    exact={route.exact}
                                    children={<route.main/>}
                                />)
                            })}

                    </AddressProvider>
                </ProfileProvider>
            </Switch>
        </Router>
  );
}

export default App;
