import React, {useState} from 'react';
import AuthHOC from "../HOC/Auth";
import { withRouter } from "react-router";
import {useForgotPassword} from "../../hooks/useAuth";
import i18next from "i18next";
import {Link} from "react-router-dom";
import Auth from "../layouts/auth";
function ForgotPassword({history}) {
    document.title="Şifremi unuttum"
    const {onEmailChange,validation,onSubmit,submit} = useForgotPassword();
    const [ email,setEmail] = useState('');
    function checkEmail(e){
        onEmailChange(e);
        if(e.target.value.length > 0){
            setEmail('input-texted');
        }else{
            setEmail('');
        }
    }
    return (
        <>
            <Auth>
                {/* LOGIN SECTION BEGIN */}
                <section id="page" className="login-register">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-4 col-lg-6">
                                {/* Login Card */}
                                <div className="cropid-login-card">
                                    {/* Card Header */}
                                    <div className="cropid-card-header">
                                        <h1>Şifremi unuttum</h1>
                                        <p>Email adresini girerek şifreni sıfırlayabilirsin.</p>
                                    </div>
                                    {/* Card Content */}
                                    <div className="cropid-card-content">
                                        <form onSubmit={(e)=>{
                                            e.preventDefault()
                                            onSubmit();
                                        }} role="form">
                                            {/* Input Group */}
                                            <div className={"cropid-input-group type-2 "+(validation?.email ? 'error' : '')}>
                                                {/* Input */}
                                                <input id="email" type="text" onChange={(e)=>{
                                                checkEmail(e)
                                            }} className={`cropid-input ${email}`} placeholder="E-mail Adresiniz" />
                                                {/* Inner Icon */}
                                                <label htmlFor="email" className="inner--icon">
                                                    <i className="cropid cropid-envelope" aria-hidden="true" />
                                                </label>
                                                {/* Error Message */}
                                                <span className="error-message">{validation.email}</span>
                                            </div>
                                            {/* Button */}
                                            <button type="submit" disabled={submit} className="cropid-button type-2">
                                                { submit ? "Lütfen bekleyin." : "Şifremi Sıfırla"}
                                            </button>
                                            {/* Speratoro */}
                                            <div className="cropid-sperator" />
                                            {/* Forgot Password */}
                                        </form>
                                    </div>
                                </div>
                                {/* Register */}
                                <div className="register">
                                    <p>Henüz bir Cropid hesabım yok!</p>
                                    <Link to={"/auth/register"} className="cropid-button type-1">
                                        Ücretsiz Kaydol
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* LOGIN SECTION END */}

            </Auth>
        </>

    );
}

export default withRouter( AuthHOC( ForgotPassword ) );
