import {useContext, useEffect, useState} from "react";
import {ProfileContext} from "../contexts/ProfileContext";
import {useHistory,useParams} from "react-router-dom";
import {forgotPassword, getInviteDetail, login, register, registerUser, resetPassword} from "../api/auth";
import storage from "../utils/storage";
import config from "../config.json";
import {error, success, urlParams} from "../utils/func";
import {del, get} from "../utils/http";
import * as R from "ramda";
import {save} from "../api/profile";


export const useProfile = () => {
    const profileC = useContext(ProfileContext);
    const [form, setForm] = useState(null);
    const [submit, setSubmit] = useState(false);
    const [validation,setValidation] = useState({});
    const history = useHistory();

    useEffect(() => {
        const setData = async () => {
            if (submit) {
                try {
                    const response = await save(form);
                    success("Profiliniz güncellenmiştir.")
                    profileC.dispatch();
                    setSubmit(false);
                } catch (e) {

                    setSubmit(false);
                    if(e.group === "validation"){
                        let validate = {};

                        for (let error in e.data){
                            validate = {...validate,[error] : e.data[error][0]};
                        }

                        setValidation(validate)
                    }else{
                        error(e.message);
                    }


                }
            }
        }
        setData();
        return () => {

        };
    }, [submit]);

    return {
        validation,
        onDataChange: (data) => {
            const key = Object.keys(data)[0]
            const value = Object.values(data)[0]
            setValidation({...validation,[key]:null});
            if(R.isEmpty(value)){
                delete form[key]
                setForm({...form})
                return null;
            }

            setForm({...form,...data});


        },
        onSubmit: () => {
            setSubmit(true);
        }
    }
};