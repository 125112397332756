import React, {useContext, useEffect, useRef, useState} from 'react';
import AuthHOC from "../HOC/Auth";
import {Link, withRouter} from "react-router-dom";
import {useLogin} from "../../hooks/useAuth";
import i18next from "i18next";
import Auth from "../layouts/auth";
import Address from "../layouts/address";
import {AddressContext} from "../../contexts/AddressContext";
import Stage3 from "./stage3";
import Stage3_1 from "./stage3.step1";

const Stage4 = ({history}) => {
    const addressContext = useContext(AddressContext);
    const [accordion,setAccordion] = useState("share");
    const [accordionC,setAccordionC] = useState(accordion);

    const prevAccordionRef = useRef();
    useEffect(() => {
        prevAccordionRef.current = accordion;
    });
    const prevAccordion = prevAccordionRef.current;

    useEffect(()=>{
        console.log(accordion,prevAccordion);
        if(accordion==prevAccordion){
            setAccordion('nothing');
        }
    },[accordionC]);

    return (
        <>
            {/* WIZARD PAGE CONTENT SECTION BEGIN */}
            <section id="page" className="wizard-content">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-8 col-lg-9">
                            {/* Wizard Content */}
                            <div className="wizard-content-inner">
                                {/* Title */}
                                <div className="title">
                                    <h6>4. Adım</h6>
                                    <h1>Paylaşım Ayarları</h1>
                                </div>
                                {/* Percentage Bar */}
                                <div className="cropid-percentageBar p-75">
                                    {/* Bar Wrapper */}
                                    <div className="bar-wrapper">
                                        {/* Bar */}
                                        <span className="percentageBar" />
                                        {/* Text */}
                                        <span className="percentageText">%75<span className="d-lg-block d-none">Paylaşıldı</span></span>
                                    </div>
                                    {/* Info Text */}
                                    <p>Gerekli adres ve kişisel bilgileri paylaştığınız kadar, üye websitelerine erişiminiz artacaktır!</p>
                                </div>
                                {/* Cropid Accordion Wrapper */}
                                <div className="cropid-accordion-wrapper">
                                    {/* Cropid Accordion */}
                                    <div className={"cropid-accordion "+(accordion=="share" ? "cropid-active" : null)}>
                                        {/* Accordion Header */}
                                        <div className="accordion-header"  onClick={()=>{
                                            setAccordion("share");
                                            setAccordionC(Math.random()*999);
                                        }}  data-cropid-accordion>
                                            {/* Title */}
                                            <div className="accordion-title">
                                                <h4>Giriş Bilgileri</h4>
                                                <p>Cropid ile üyelik formlarıyla uğraşma bir kod ve şifre.</p>
                                            </div>
                                            {/* Percentage */}
                                            <div className="percentage">
                                                <span className="percentageText">%75<span>Paylaşıldı</span></span>
                                            </div>
                                            {/* Arrow */}
                                            <span className="arrow" />
                                        </div>
                                        {/* Accordion Content Wrapper */}
                                        <div className="accordion-content-wrapper">
                                            <form className="cropid-form flex two">
                                                {/* Form Group */}
                                                <div className="cropid-form-group">
                                                    {/* Checbox Group */}
                                                    <div className="cropid-checkbox-group type-2">
                                                        {/* Input */}
                                                        <input type="checkbox" id="userCheck" className="cropid-checkbox"  onClick={(e)=>{
                                                            if(e.target.checked){
                                                                addressContext.dispatch({stage_4:{...addressContext.data.stage_4,fullname_share:true}});
                                                            }else{
                                                                addressContext.dispatch({stage_4:{...addressContext.data.stage_4,fullname_share:false}});
                                                            }
                                                        }} defaultChecked={addressContext.data.stage_4.fullname_share} />
                                                        {/* Label */}
                                                        <label htmlFor="userCheck">
                                                            {/* Icon */}
                                                            <i className="cropid cropid-user" aria-hidden="true" />
                                                            {/* Text */}
                                                            <span className="text">{addressContext.data.stage_2.firstname + " "+ addressContext.data.stage_2.lastname}</span>
                                                            {/* Toggle */}
                                                            <span className="toggle" />
                                                        </label>
                                                    </div>
                                                    {/* Cropid Info */}
                                                    <div className="cropid-info" data-cropid-tooltip="Buraya hangi sitede olduğu yazabilir.">
                                                        {/* Inner Icon */}
                                                        <i className="cropid cropid-info" aria-hidden="true" />
                                                        {/* Inner Text */}
                                                        <p>Cropid sitelerinin <span className="cropid--primary1">%25‘ine giriş</span> yaparken bu bilgiyi girmeniz gerekir.</p>
                                                    </div>
                                                </div>
                                                {/* Form Group */}
                                                <div className="cropid-form-group">
                                                    {/* Checbox Group */}
                                                    <div className="cropid-checkbox-group type-2">
                                                        {/* Input */}
                                                        <input type="checkbox" id="phoneCheck" className="cropid-checkbox" onClick={(e)=>{
                                                            if(e.target.checked){
                                                                addressContext.dispatch({stage_4:{...addressContext.data.stage_4,phone_share:true}});
                                                            }else{
                                                                addressContext.dispatch({stage_4:{...addressContext.data.stage_4,phone_share:false}});
                                                            }
                                                        }} defaultChecked={addressContext.data.stage_4.phone_share} />
                                                        {/* Label */}
                                                        <label htmlFor="phoneCheck">
                                                            {/* Icon */}
                                                            <i className="cropid cropid-phone" aria-hidden="true" />
                                                            {/* Text */}
                                                            <span className="text">{addressContext.data.stage_2.phone}</span>
                                                            {/* Toggle */}
                                                            <span className="toggle" />
                                                        </label>
                                                    </div>
                                                    {/* Cropid Info */}
                                                    <div className="cropid-info" data-cropid-tooltip="Buraya hangi sitede olduğu yazabilir.">
                                                        {/* Inner Icon */}
                                                        <i className="cropid cropid-info" aria-hidden="true" />
                                                        {/* Inner Text */}
                                                        <p>Cropid sitelerinin <span className="cropid--primary1">%25‘ine giriş</span> yaparken bu bilgiyi girmeniz gerekir.</p>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    {/* Cropid Accordion */}
                                    <div className={"cropid-accordion "+(accordion=="delivery_address" ? "cropid-active" : null)}>
                                        {/* Accordion Header */}
                                        <div className="accordion-header" data-cropid-accordion  onClick={()=>{
                                            setAccordion("delivery_address")
                                            setAccordionC(Math.random()*999);
                                        }} >
                                            {/* Title */}
                                            <div className="accordion-title">
                                                <h4>Adres Bilgileri</h4>
                                                <p>Cropid ile üyelik formlarıyla uğraşma bir kod ve şifre.</p>
                                            </div>
                                            {/* Percentage */}
                                            <div className="percentage">
                                                <span className="percentageText">%100<span>Paylaşıldı</span></span>
                                            </div>
                                            {/* Arrow */}
                                            <span className="arrow" />
                                        </div>
                                        {/* Accordion Content Wrapper */}
                                        <div className="accordion-content-wrapper">
                                            <Stage3 history={history} onlyForm={true}/>
                                        </div>
                                    </div>
                                    {/* Cropid Accordion */}
                                    <div className={"cropid-accordion "+(accordion=="invoice_address" ? "cropid-active" : null)}>
                                        {/* Accordion Header */}
                                        <div className="accordion-header"  onClick={()=>{
                                            setAccordion("invoice_address")
                                            setAccordionC(Math.random()*999);
                                        }}  data-cropid-accordion>
                                            {/* Title */}
                                            <div className="accordion-title">
                                                <h4>Fatura Bilgileri</h4>
                                                <p>Cropid ile üyelik formlarıyla uğraşma bir kod ve şifre.</p>
                                            </div>
                                            {/* Percentage */}
                                            <div className="percentage">
                                                <span className="percentageText">%100<span>Paylaşıldı</span></span>
                                            </div>
                                            {/* Arrow */}
                                            <span className="arrow" />
                                        </div>
                                        {/* Accordion Content Wrapper */}
                                        <div className="accordion-content-wrapper">
                                            <Stage3_1 history={history} onlyForm={true}/>
                                        </div>
                                    </div>
                                </div>
                                {/* Content Footer */}
                                <div className="content-footer">
                                    {/* Back Button */}
                                    <a href="#" onClick={(e)=>{
                                        e.preventDefault();
                                        addressContext.dispatch({active_page:3.1});
                                    }} className="back-btn s">
                                        {/* Icon */}
                                        <i className="cropid cropid-arrow-left" />
                                        {/* Inner Text */}
                                        <span className="inner--text">Adres Bilgileri</span>
                                    </a>
                                    {/* Continue Button */}
                                    <a href="#" onClick={(e)=>{
                                        e.preventDefault();

                                        if(addressContext.data.stage_4.submit===undefined || addressContext.data.stage_4.submit===false){
                                            addressContext.save();
                                        }


                                    }}  className="cropid-button type-1 with-icon">
                                        {/* Inner Text */}
                                        <span className="inner--text">Kaydet ve Tamamla</span>
                                        {/* Inner Icon */}
                                        <span className="inner--icon">
                <i className="cropid cropid-arrow-up-right" aria-hidden="true" />
              </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* WIZARD PAGE CONTENT SECTION END */}


        </>
    );
}

export default Stage4