import * as R from "ramda";
import config from '../config';
import React from "react";
import i18next from "i18next";
import money from "currency-formatter";
import moment from "moment";
import {toast} from "react-toastify";

const cleanNullOneLevel = data => R.filter(i => i !== null, data);

export function capitalize ([first, ...rest], lowerRest = false)  {
    return first.toUpperCase() + (lowerRest ? rest.join('').toLowerCase() : rest.join(''));
}


export function cleanNull (data) {
    return R.compose(
        R.map(item => {
            if (R.is(Object, item)) {
                return cleanNullOneLevel(cleanNull(item));
            } else {
                return item;
            }
        }),
        cleanNullOneLevel
    )(data);
};


export function headersAction(){

    const token = localStorage.getItem(config.token_name) || false;

    if(token !== false){
        return {
            Authorization: "Bearer " + token
        }
    }

    return {}
};

export function setCookie(name,value,days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + ";domain=.cropid.com;path=/";
}


export function dateFormat(date,format="DD MMM YYYY"){
    if(date == undefined) return null;
    return moment(date).format(format);
}

export function checkUrl (url,search,active='active',deactive='') {
    return url.indexOf(search)>-1 ? active : deactive;
}

export function error(message){
    toast.error(i18next.t(message), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,

    });
}

export function success(message=""){
    toast.success( i18next.t(message), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
}
export function urlParams (location) {

    return new URLSearchParams(location);
};

export function params (key){
    return urlParams(window.location.search).get(key);
}

export function nameParser(name){

    const fullName = name.split(' ');

    const lastName = fullName.pop();
    const firstName = fullName.join(' ');
    return {
        firstName:firstName,
        lastName:lastName
    }
}


export function redirectLogin(){
    const urlHash = window.location.href;
    const isInnerPage = !urlHash.includes('/auth/');
    if (isInnerPage) {
        window.location = '/auth/login';
    }
}

export function selectStyles(){
    return {

        control: (base, state) => ({
            ...base,
            background: "#fff",
            borderRadius:"15px",
            padding:"7px 0px 7px 7px",
            height:"56px",
            borderColor: "#e6e6ec",
            //boxShadow: state.isFocused ? "0 0 0 1px #05ce7b" : null,
            boxShadow: state.isFocused ? null : null,
            '&:hover': {
                borderColor: '#e6e6ec'
              }
        }),
        placeholder:(base,state) => ({
            ...base,
            color:"#141b5f",
            opacity:0.6,
            fontSize:14,
            fontWeight:600,
            fontFamily:"OpenSans"
        }),
        indicatorsContainer:(base,state) => ({
            ...base,
            color:"#141b5f",
            paddingRight: "10px",
        }),
        indicatorContainer:(base,state) => ({
            ...base,
            color:"#141b5f",
        }),
        indicatorSeparator:(base,state) => ({
            ...base,
            display: "none"
        }),
        singleValue:(base,state) => ({
            ...base,
            color:"#141b5f",
            fontWeight: "600",
            fontSize: "14px"

        }),
        option: (provided, state) => ({
            ...provided,
            borderTop: state.isSelected ? null : '1px solid #eee',
            color: state.isSelected ? '#fff' : '#141b5f',
            backgroundColor: state.isSelected ? '#05ce7b' : '#fff',
            padding:"10px 15px",
            fontSize:"15px",
            fontWeight:"500"
          }),
          menu: (provided, state) => ({
            ...provided,
            padding: "0",
            overflow: "hidden",
            border:"0",
            boxShadow: "0 10px 20px -10px rgba(0,0,0,.3)",
            borderRadius: "15px"
          }),
    };
}

export function addressTypes (type) {
    const types = {
        home: {
            icon:"🏠",
            name:"Ev Adresi"
        },
        school: {
            icon:"🎒",
            name:"Okul Adresi"
        },
        office: {
            icon:"💼",
            name:"Okul Adresi"
        }
    }

    return types[type];
}
