import {useContext, useEffect, useState} from "react";
import {ProfileContext} from "../contexts/ProfileContext";
import {useHistory,useParams} from "react-router-dom";
import {forgotPassword, getInviteDetail, login, register, registerUser, resetPassword} from "../api/auth";
import storage from "../utils/storage";
import config from "../config.json";
import {error, success, urlParams} from "../utils/func";
import {del, get} from "../utils/http";
import * as R from "ramda";
import {save} from "../api/profile";
import {getRegionList} from "../api/address";

const getRegion = async (parent,callback,child=false) => {
    const res = await getRegionList(parent,child);
    await callback(res);

    return res;
}

const regionMap = function (r) {

    if(r?.children){
        let opts = r.children.map((i)=>{
            return { label: i.name, value: i.id };
        });
        return {
            label: r.name,
            options: opts
        }
    }
    return { value: r.id, label: r.name };
}

export const useAddress = () => {

    const [countryList,setCountryList] = useState([]);
    const [cityList,setCityList] = useState([]);
    const [districtList,setDistrictList] = useState([]);
    const [neighborhoodList,setNeighborhoodList] = useState([]);
    const [country,setCountry] = useState(0);
    const [city,setCity] = useState(0);
    const [district,setDistrict] = useState(0);
    const [neighborhood,setNeighborhood] = useState(0);

    useEffect(()=>{
        getRegion(0,(data) => {
            const result = data.map(regionMap)
            setCountryList(result);
        })
    },[]);

    useEffect(()=>{
        if(country>0){
            getRegion(country,(data) => {
                const result = data.map(regionMap)
                setCityList(result);
                setDistrictList([]);
                setNeighborhoodList([]);
            })
        }
    },[country]);

    useEffect(()=>{
        if(city>0) {
            getRegion(city, (data) => {
                const result = data.map(regionMap)
                setDistrictList(result);
                setNeighborhoodList([]);
            })
        }
    },[city]);

    useEffect(()=>{
        if(district>0) {
            getRegion(district, (data) => {
                const result = data.map(regionMap)
                setNeighborhoodList(result);
            },true)
        }
    },[district]);




    return {
        countryList,
        cityList,
        districtList,
        neighborhoodList,
        //getter,setters
        country,
        city,
        district,
        neighborhood,
        setCountry,
        setCity,
        setDistrict,
        setNeighborhood,
    }
};