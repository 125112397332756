import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import storage from "./utils/storage";
import {post} from "./utils/http";
import i18n from "i18next";
import {initReactI18next, I18nextProvider} from "react-i18next";
import config from './config';
import * as lang from './locales';
import 'moment/locale/tr';
import * as moment from "moment";
import ReactTooltip from "react-tooltip";
import ReactGA from 'react-ga';
import { hotjar } from 'react-hotjar';
import {redirectLogin} from "./utils/func";
const resources = {
    en: {
        translation: lang.en
    },
    tr: {
        translation: lang.tr
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: resources,
        lng: "tr",
        fallbackLng: "tr",
        interpolation: {
            escapeValue: false
        },
        keySeparator:false
    });
function renderApp() {

        ReactDOM.render(
            <React.StrictMode>
                <I18nextProvider i18n={i18n}>
                    <App />
                    <ToastContainer />
                </I18nextProvider>
            </React.StrictMode>,
            document.getElementById('root')
        );


}
//i18n.changeLanguage("tr");
async function loadStartData() {

    const data = {token: storage.get(config.token_name),lang:storage.get(config.locale)};
    i18n.changeLanguage(storage.get("language",config.locale));
    try{

        await post('/auth/check',data);
        renderApp();
    } catch (e) {
        renderApp();
    }
}

loadStartData();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
