import React, {useContext, useEffect, useState} from 'react';
import {AddressContext} from "../../contexts/AddressContext";
import * as R from 'ramda';
import {Link} from "react-router-dom";
import {useAddress} from "../../hooks/useAddress";
import {add} from "ramda";
import {params} from "../../utils/func";

const Stage1 = ({history,match}) => {
    const addressContext = useContext(AddressContext);
    const {queryCode} = useAddress();
    let queryRef = [];

    useEffect(() => {
        if(params("code")!==null){
                addressContext.query();
        }
    },[]);


    return (
        <>
            {/* WIZARD PAGE CONTENT SECTION BEGIN */}
            <section id="page" className="wizard-content">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-6 col-lg-7">
                            {/* Wizard Content */}
                            <div className="wizard-content-inner">
                                {/* Title */}
                                <div className="title">
                                    <h6>1. Adım</h6>
                                    <h1>Kodunuzu Oluşturun</h1>
                                </div>
                                {/* Info */}
                                <div className="info">
                                    <i className="cropid cropid-info" aria-hidden="true" />
                                    <p>Kolay hatırlayacağınız kodu belirleyin ve hemen kullanmaya başlayın. <br />Her kod bir kez kullanılabilir.</p>
                                </div>
                                {/* Form */}
                                <form role="form">
                                    {/* Input Group */}
                                    <div className={"cropid-input-group type-3 "+(addressContext.data.stage_1?.error==="" || addressContext.data.stage_1?.error===false ? (addressContext.data.stage_1?.error===false ? "success" : null) : 'error')}>
                                        {/* Input */}
                                        <input type="text" key={"stage_1_code"} onChange={(e)=>{
                                           
                                            addressContext.dispatch({stage_1:{...addressContext.data.stage_1,code:e.target.value,clone_code:e.target.value,error:"",success:false}});
                                        }} placeholder={"Kendinize bir kod belirleyin"}

                                           value={addressContext.data.stage_1.code} />
                                        {/* Button */}
                                        <button type="button" ref={el => {
                                            queryRef=el
                                        }} className="cropid-button" onClick={()=>{
                                            addressContext.query();
                                        }}>
                                            {/* Inner Text */}
                                            <span className="inner--text">ara</span>
                                            {/* Inner Icon */}
                                            <span className="inner--icon">
                  <i className="cropid cropid-magnifier" aria-hidden="true" />
                </span>
                                        </button>
                                    </div>
                                    {/* Error Message */}

                                    {addressContext.data.stage_1?.error ? <p className="cropid--error">{addressContext.data.stage_1.error}</p> : null}
                                </form>
                                {/* Suggestions */}
                                <div className={"suggestions "+(addressContext.data.stage_1.similar===false ? 'hidden' : null)}>
                                    {/* Title */}
                                    <div className="title">
                                        <h3>Öneriler</h3>
                                    </div>
                                    {/* Tags */}
                                    <div className="tags">
                                        {/* Radio Group */}
                                        {
                                            [34,44,1].map((item,i)=>{
                                                return (
                                                    <div key={i} className="cropid-radio-group type-1" onClick={()=>{
                                                        addressContext.dispatch({stage_1:{...addressContext.data.stage_1,code:(addressContext.data.stage_1.clone_code+item)}});
                                                    }}>
                                                        <input id={"sug"+i} type="radio" name="suggestions" className="cropid-radio" />
                                                        <label htmlFor={"sug"+i}>{addressContext.data.stage_1.clone_code+item}</label>
                                                    </div>
                                                );
                                            })
                                        }


                                    </div>
                                </div>

                                <div className="content-footer mobile-reverse">
                                    {/* Back Button */}
                                    <a href="" onClick={(e)=>{
                                        e.preventDefault();
                                        history.push('/');
                                        addressContext.reset();
                                    }} className="back-btn">
                                        {/* Icon */}
                                        <i className="cropid cropid-arrow-left" />
                                        {/* Inner Text */}
                                        <span className="inner--text">Geri Dön</span>
                                    </a>
                                    {/* Continue Button */}
                                    <a href="#" onClick={(e)=>{
                                        e.preventDefault();


                                        if(addressContext.data.stage_1.code===""){
                                            addressContext.dispatch({stage_1:{...addressContext.data.stage_1,error:"Lütfen bir kod belirleyin.",success:false}});
                                        }else if(addressContext.data.stage_1.success===false){
                                            addressContext.dispatch({stage_1:{...addressContext.data.stage_1,error:"Lütfen kodunuzu onaylanıyız.",success:false}});
                                        }else{
                                            addressContext.dispatch({active_page:2,current_page:2});
                                        }

                                    }} className="cropid-button type-1 with-icon">
                                        {/* Inner Text */}
                                        <span className="inner--text">Devam Et</span>
                                        {/* Inner Icon */}
                                        <span className="inner--icon">
                <i className="cropid cropid-arrow-up-right" aria-hidden="true" />
              </span>
                                    </a>
                                    {/* Reset Button */}
                                    <a href="#" className="reset-btn">
                                        {/* Icon */}
                                        <i className="cropid cropid-refresh" aria-hidden="true" />
                                        {/* Inner Text */}
                                        <span className="inner--text">Sıfırla</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* WIZARD PAGE CONTENT SECTION END */}

        </>
    );
}

export default Stage1