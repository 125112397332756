import React, {useEffect, useState} from 'react';
import AuthHOC from "../HOC/Auth";
import { withRouter } from "react-router";
import {useResetPassword} from "../../hooks/useAuth";
import {urlParams} from "../../utils/func";
import i18next from "i18next";
import {Link} from "react-router-dom";
import Auth from "../layouts/auth";
function ResetPassword({history,match,location}) {
    document.title="Şifreni Sıfırla"
    const {onPasswordChange,onRePasswordChange,onSubmit,validation,setToken,setEmail} = useResetPassword();

    useEffect(()=>{
        const token = match.params.token;
        const query = urlParams(location.search);
        setEmail(query.get("email"));
        setToken(token)
    });
    const [ pass,setPass] = useState('');
    const [ passConfirm,setPassConfirm] = useState('');
    function checkPass(e){
        onPasswordChange(e);
        if(e.target.value.length > 0){
            console.log(e.target.value);
            setPass('input-texted');
        }else{ 
            setPass('');
        }
    }
    function checkPassConfirm(e){
        onRePasswordChange(e);
        if(e.target.value.length > 0){
            setPassConfirm('input-texted');
        }else{
            setPassConfirm('');
        }
    }
   
    return (
        <Auth>
            {/* REGISTER SECTION BEGIN */}
            <section id="page" className="login-register">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-4 col-lg-6">
                            {/* Login Card */}
                            <div className="cropid-login-card">
                                {/* Card Header */}
                                <div className="cropid-card-header">
                                    <h1>Şifre Sıfırlama</h1>
                                    <p>Lütfen yeni şifrenizi giriniz.</p>
                                </div>
                                {/* Card Content */}
                                <div className="cropid-card-content">
                                    <form onSubmit={(e)=>{
                                        e.preventDefault();
                                        onSubmit();
                                    }} role="form">
                                        <div className={"cropid-input-group type-2 "+(validation?.password ? "error" : "")}>
                                            {/* Input */}
                                            <input type="password" onChange={(e)=>{
                                                checkPass(e)
                                            }}  type="password" className={`cropid-input ${passConfirm}`} placeholder="Şifreniz" />
                                            {/* Inner Icon */}
                                            <label htmlFor="pwd" className="inner--icon">
                                                <i className="cropid cropid-lock" aria-hidden="true" />
                                            </label>
                                            {/* Error Message */}
                                            <span className="error-message">{validation?.password}</span>
                                        </div>
                                        {/* Input Group */}
                                        <div className={"cropid-input-group type-2 "+(validation?.rePassword ? "error" : "")}>
                                            {/* Input */}
                                            <input id="pwdCnfrm" onChange={(e)=>{
                                                checkPassConfirm(e)
                                            }}  type="password" className={`cropid-input ${passConfirm}`} placeholder="Şifreniz Tekrar" />
                                            {/* Inner Icon */}
                                            <label htmlFor="pwdCnfrm" className="inner--icon">
                                                <i className="cropid cropid-lock" aria-hidden="true" />
                                            </label>
                                            {/* Error Message */}
                                            <span className="error-message">{validation?.rePassword}</span>
                                        </div>

                                        {/* Button */}
                                        <button type="submit" className="cropid-button type-2">Şifremi Sıfırla</button>
                                    </form>
                                </div>
                            </div>
                            {/* Register */}
                            <div className="register">
                                <p>Kaydolmaya ihtiyacım yok, zaten bir hesabım var!</p>
                                <Link to="/auth/login" className="cropid-button type-1">Giriş Yap</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* REGISTER SECTION END */}

        </Auth>
    );
}

export default withRouter( AuthHOC( ResetPassword ) );
