import {ProfileContext, ProfileProvider} from "../../contexts/ProfileContext";
import React, {useContext, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {dateFormat} from "../../utils/func";
import {AddressContext} from "../../contexts/AddressContext";


const Address = ({children,sidebar= f=>f,title='Dashboard',sidebarBottom= f=>f,onSearch= f=>f}) => {
    const context = useContext(AddressContext);


    return (
            <>
                {/* WIZARD PAGE HEADER BEGIN */}
                <section id="wizard-header">
                        <div className="container-fluid">
                                {/* Wizard List */}
                                <ol className="wizard-list">
                                    <li className={"wizard-item "+(context.data.active_page>=1 ? 'cropid-active' : '')}><a href="#" onClick={(e)=>{
                                        e.preventDefault();
                                        if(context.data.current_page>=1 ){
                                            context.dispatch({active_page:1})
                                        }

                                    }}><span>Kodunuz</span><span>{context.data.stage_1?.code}</span></a></li>
                                    <li className={"wizard-item "+(context.data.active_page>=2 ? 'cropid-active' : '')}><a href="#"  onClick={(e)=>{
                                        e.preventDefault();
                                        if(context.data.current_page>=2 && context.data.stage_1.success){
                                            context.dispatch({active_page:2})
                                        }

                                    }}><span>Giriş Bilgileri</span><span>{context.data.current_page>2 ? 'Tamamlandı' : ''}</span></a></li>
                                    <li className={"wizard-item "+(context.data.active_page>=3 ? 'cropid-active' : '') + ' ' +(context.data.current_page==3 ? 'half' : '')} ><a href="#"  onClick={(e)=>{
                                        e.preventDefault();
                                        if(context.data.current_page>=3){
                                            context.dispatch({active_page:3})
                                        }

                                    }}><span>Adres Bilgileri</span><span>{context.data.current_page>3 ? 'Tamamlandı' : ''}</span></a></li>
                                    <li className={"wizard-item "+(context.data.active_page>=4 ? 'cropid-active' : '')}><a href="#"  onClick={(e)=>{
                                        e.preventDefault();
                                        if(context.data.current_page>=4){
                                            context.dispatch({active_page:4})
                                        }

                                    }}><span>Paylaşım Ayarları</span><span>{context.data.current_page>4 ? 'Tamamlandı' : ''}</span></a></li>
                                </ol>
                            {/* Mobile Wizard Content */}
                            <div className="d-block d-lg-none">
                                <div className="wizard-mobile">
                                    <span>Kodunuz</span>
                                    <span>{context.data.stage_1?.code}</span>
                                </div>
                            </div>
                        </div>
                </section>
                {/* WIZARD PAGE HEADER END */}

                {children}


            </>
    )
};

export default Address;
