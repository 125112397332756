import React, {useContext, useState} from 'react';
import {Link, withRouter} from "react-router-dom";
import {ProfileContext} from "../../contexts/ProfileContext";
import AppHOC from "../HOC/App";
import Layout from "../layouts";
import {useProfile} from "../../hooks/useProfile";

const Profile = ({history}) => {
    document.title="Hesabım"
    const context = useContext(ProfileContext);
    const {validation,onDataChange,onSubmit} = useProfile()

    return (
        <Layout>
            {/* PAGE SECTION BEGIN */}
            <section id="page" className="edit-address">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            {/* Welcome Text */}
                            <div className="welcome-text">
                                <span>HESAP AYARLARI</span>
                                <h4>{context.value.name}</h4>
                            </div>
                            {/* Settings Form */}
                            <form onSubmit={(e)=>{
                                e.preventDefault()
                                onSubmit();
                            }} className="cropid-form">
                                {/* Form Group */}
                                <div className="cropid-form-group cropid-title">
                                    {/* Input Group */}
                                    <p>Adınız soyadınız</p>
                                    <div className={"cropid-input-group type-2 "+(validation?.name ? 'error' : '')}>
                                        {/* Input */}
                                        <input id="firstName" onChange={(e)=>{
                                            onDataChange({name:e.target.value})
                                        }} type="text" className="cropid-input" defaultValue={context.value.name} />
                                        {/* Inner Icon */}
                                        <label htmlFor="firstName" className="inner--icon">
                                            <i className="cropid cropid-user" aria-hidden="true" />
                                        </label>
                                        <span className="error-message">{validation?.name}</span>

                                    </div>

                                    {/* Input Group */}
                                </div>
                                {/* Cropid Sperator */}
                                <div className="cropid-sperator" />
                                {/* Cropid Title */}
                                <div className="cropid-title">
                                    <h3>Giriş Bilgileri</h3>
                                    <p>Dilerseniz giriş bilgilerinizi değiştirebilirsiniz..</p>
                                </div>
                                {/* Form Group */}
                                <div className="cropid-form-group cropid-title" style={{margin:0}}>
                                    <p>Email adresiniz</p>
                                    {/* Input Group */}
                                    <div className={"cropid-input-group type-2 "+(validation?.email ? 'error' : '')}>
                                        {/* Input */}
                                        <input id="email" onChange={(e)=>{
                                            onDataChange({email:e.target.value})
                                        }}  type="text" className="cropid-input" defaultValue={context.value.email} />
                                        {/* Inner Icon */}
                                        <label htmlFor="email" className="inner--icon">
                                            <i className="cropid cropid-lock" aria-hidden="true" />
                                        </label>

                                        <span className="error-message">{validation?.email}</span>

                                    </div>

                                </div>
                                {/* Form Group */}
                                <div className="form-row ikili">
                                    <div className="form-item">
                                <div className="cropid-form-group flex two cropid-title" style={{margin:0}}>
                                    <p>Yeni Şifreniz</p>
                                </div>
                                    {/* Input Group */}
                                    <div className={"cropid-input-group type-2 "+(validation?.password ? 'error' : '')}>
                                        {/* Input */}
                                        <input id="pwd" onChange={(e)=>{
                                            onDataChange({password:e.target.value})
                                        }}  type="password" autoComplete={"new-password"} className="cropid-input" placeholder="Yeni Şifre" />
                                        {/* Inner Icon */}
                                        <label htmlFor="pwd" className="inner--icon">
                                            <i className="cropid cropid-lock" aria-hidden="true" />
                                        </label>
                                        <span className="error-message">{validation?.password}</span>
                                    </div>
                                    {/* Input Group */}
                            </div>
                            <div className="form-item">
                    <div className="cropid-form-group flex two cropid-title" style={{margin:0}}>
                                    <p>Yeni Şifreniz Tekrar</p>
                                </div>
                                    <div className="cropid-input-group type-2">
                                        {/* Input */}
                                        <input id="pwd2" onChange={(e)=>{
                                            onDataChange({password_confirmation:e.target.value})
                                        }}   type="password" autoComplete={"re-new-password"} className="cropid-input" placeholder="Yeni Şifre Tekrar" />
                                        {/* Inner Icon */}
                                        <label htmlFor="pwd2" className="inner--icon">
                                            <i className="cropid cropid-lock" aria-hidden="true" />
                                        </label>
                                        </div>      
                                    </div>
                                </div>
                                {/* Cropid Tag Wrapper */}
                                <div className="cropid-tag-wrapper">

                                    {
                                        context.value.slugs?.map((item,i)=>{
                                            return (
                                                <Link key={i} to={"/address/"+item}>
                                                    <div className="cropid-tag type-1 c-p">
                                                        {/* Inner Icon */}
                                                        <i className="cropid cropid-link" aria-hidden="true" />
                                                        {/* Inner Text */}
                                                        <span className="inner--text">{item}</span>
                                                    </div>
                                                </Link>
                                            );
                                        })
                                    }

                                </div>
                                {/* Cropid Info */}
                                <div className="cropid-info cropid--primary2 align-items-center">
                                    {/* Inner Icon */}
                                    <i className="cropid cropid-info mt-0" aria-hidden="true" />
                                    {/* Inner Text */}
                                    <p>Ayrıca oluşturduğunz kodlar ile de hesabınıza giriş yapabilirsiniz.</p>
                                </div>

                            {/* Content Footer */}
                            <div className="content-footer mobile-reverse">
                                {/* Back Button */}
                                <a href="" onClick={(e)=>{
                                    e.preventDefault();
                                    history.push('auth/logout');
                                }} className="reset-btn logout-btn">
                                        {/* Icon */}
                                        <i className="cropid cropid-exit" />
                                        {/* Inner Text */}
                                        <span className="inner--text">Çıkış Yap</span>
                                </a>
                                {/* Continue Button */}
                                <button type="submit" className="cropid-button type-1 with-icon">
                                    {/* Inner Text */}
                                    <span className="inner--text">Kaydet</span>
                                    {/* Inner Icon */}
                                    <span className="inner--icon">
              <i className="cropid cropid-arrow-up-right" aria-hidden="true" />
            </span>
                                </button>

                            </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            {/* PAGE SECTION END */}

        </Layout>
    );
}

export default withRouter (AppHOC(Profile));
