import React, {useState} from 'react';
import AuthHOC from "../HOC/Auth";
import {Link, withRouter} from "react-router-dom";
import {useLogin} from "../../hooks/useAuth";
import i18next from "i18next";
import Auth from "../layouts/auth";

const Login = ({history}) => {
    document.title="Giriş yap"
    const { onEmailChange,onPasswordChange,onRememberChange,onSubmit } = useLogin();
    const [ email,setEmail] = useState('');
    const [ pass,setPass] = useState('');
    function checkEmail(e){
        onEmailChange(e);
        if(e.target.value.length > 0){
            setEmail('input-texted');
        }else{
            setEmail('');
        }
    }
    function checkPass(e){
        onPasswordChange(e);
        if(e.target.value.length > 0){
            setPass('input-texted');
        }else{
            setPass('');
        }
    }
    return (
        <Auth>
            {/* LOGIN SECTION BEGIN */}
            <section id="page" className="login-register">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-4 col-lg-5">
                            {/* Login Card */}
                            <div className="cropid-login-card">
                                {/* Card Header */}
                                <div className="cropid-card-header">
                                    <h1>Giriş Yap</h1>
                                    <p>Cropid kodun, e-mail adresin ile giriş yapabilirsin.</p>
                                </div>
                                {/* Card Content */}
                                <div className="cropid-card-content">
                                    <form onSubmit={(e)=>{
                                        e.preventDefault()
                                        onSubmit();
                                    }} role="form">
                                        {/* Input Group */}
                                        <div className="cropid-input-group type-2">
                                            {/* Input */}
                                            <input id="email" type="text" onChange={(e)=>{
                                                checkEmail(e)
                                            }} className={`cropid-input ${email}`} placeholder="E-mail Adresiniz veya kodunuz" />
                                            {/* Inner Icon */}
                                            <label htmlFor="email" className="inner--icon">
                                                <i className="cropid cropid-envelope" aria-hidden="true" />
                                            </label>
                                            {/* Error Message */}
                                            <span className="error-message">Lütfen geçerli bir e-mail adresi giriniz.</span>
                                        </div>
                                        {/* Input Group */}
                                        <div className="cropid-input-group type-2">
                                            {/* Input */}
                                            <input id="pwd" type="password" onChange={(e)=>{
                                                checkPass(e); 
                                            }} className={`cropid-input ${pass}`}  placeholder="Şifreniz" />
                                            {/* Inner Icon */}
                                            <label htmlFor="pwd" className="inner--icon">
                                                <i className="cropid cropid-lock" aria-hidden="true" />
                                            </label>
                                        </div>
                                        {/* Checkbox Group */}
                                        <div className="cropid-checkbox-group type-1">
                                            {/* Input */}
                                            <input onChange={(e)=>{
                                                onRememberChange(e)
                                            }} type="checkbox" id="remember" className="cropid-checkbox" />
                                            {/* Label */}
                                            <label htmlFor="remember">
                                                <span>Beni hatırla</span>
                                            </label>
                                        </div>
                                        {/* Button */}
                                        <button type="submit" className="cropid-button type-2 fw-600">Giriş Yap</button>
                                        {/* Speratoro */}
                                        <div className="cropid-sperator" />
                                        {/* Forgot Password */}
                                        <Link to={"/auth/forgot-password"} className={"forgot link-hover"}>
                                            Şifremi Unuttum
                                        </Link>
                                    </form>
                                </div>
                            </div>
                            {/* Register */}
                            <div className="register">
                                <p>Henüz bir Cropid hesabım yok!</p>
                                <Link to={"/auth/register"} className="cropid-button type-1">
                                    Ücretsiz Kaydol
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* LOGIN SECTION END */}

        </Auth>

    );
}

export default withRouter (AuthHOC(Login));
