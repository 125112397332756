import React, {useContext, useEffect, useState} from 'react';
import {Link, useHistory, withRouter} from "react-router-dom";
import {ProfileContext} from "../../contexts/ProfileContext";
import AppHOC from "../HOC/App";
import Layout from "../layouts";
import {AddressContext} from "../../contexts/AddressContext";
import {addressTypes} from "../../utils/func";


const Dashboard = ({history}) => {
    document.title="Adreslerim"
    const context = useContext(ProfileContext);
    const addressContext = useContext(AddressContext);
    const [order,setOrder] = useState("id");
    const [orderAsc,setOrderAsc] = useState(true);
    const [editItem,setEditItem] = useState(0);

    useEffect(()=>{
        addressContext.refresh({
            order:order,
            by:orderAsc ? "asc" : "desc"
        });
    },[order,orderAsc])

    return (
       <Layout>
           <section id="page" className="dashboard" onBlur={()=>{
               setEditItem(0);
           }}>
               <div className="container">
                   <div className="row justify-content-center">
                       {/* Welcome Text */}
                       <div className="col-lg-9">
                           <div className="welcome-text">
                               <span>HOŞ GELDİN</span>
                               <h4>{context.value.name}</h4>
                           </div>
                           {/* Addresses */}
                           <div className="addresses">
                               {/* Info Bar */}
                               <div className="info-bar">
                                   <div className="count">
                                       {/* Icon */}
                                       <div className="icon">
                                           <svg width="130.78" height="130.2" viewBox="0 0 130.78 130.2">
                                               <use xlinkHref="/assets/images/cropid.svg#cropid-house" />
                                           </svg>
                                       </div>
                                       {/* Text */}
                                       <div className="text">
                                           <span>Adresler</span>
                                           <p>{addressContext.address?.total_address}</p>
                                       </div>
                                   </div>
                                   {/* Button */}
                                   <Link to="/new-address" className="cropid-button type-2 with-icon left text-small">
                                       {/* Inner Icon */}
                                       <span className="inner--icon">
                <i className="cropid cropid-link" aria-hidden="true" />
              </span>
                                       {/* Inner Text */}
                                       <span className="inner--text">
                Yeni Bir Adres Ekle
              </span>
                                   </Link>
                               </div>
                               {/* List */}
                               <div className="list">
                                   {/* List Header */}
                                   <div className="list-header">
                                       <div>
                                           <h5>Kayıtlı Adreslerim</h5>
                                           <p>Cropid ile üyelik formlarıyla uğraşma bir kod ve şifre.</p>
                                       </div>
                                       {/* Search Button */}
                                       <a href="#" className="cropid-button icon-button hidden">
                                           <i className="cropid cropid-magnifier" aria-hidden="true" />
                                       </a>
                                   </div>
                                   {/* List */}
                                   <div className="cropid-list address-list">
                                       {/* List Head */}
                                       <div className="list-head">
                                           <div className={"head-item d-lg-block d-none "+(order=="id" ? "cropid-active" : null)} onClick={()=>{

                                               if(order=="id"){
                                                   setOrderAsc(!orderAsc);
                                               }

                                               setOrder("id");
                                           }}><span>Sıra</span></div>
                                           <div className={"head-item "+(order=="slug" ? "cropid-active" : null)} onClick={()=>{

                                               if(order=="slug"){
                                                   setOrderAsc(!orderAsc);
                                               }

                                               setOrder("slug");
                                           }}><span>Kodunuz</span></div>
                                           <div className={"head-item d-lg-block d-none "+(order=="type" ? "cropid-active" : null)} onClick={()=>{

                                               if(order=="type"){
                                                   setOrderAsc(!orderAsc);
                                               }
                                               setOrder("type");

                                           }}><span>Adres Tipi</span></div>
                                           {/* Search Button */}
                                           <a href="#" className="cropid-button icon-button d-flex ml-auto">
                                               <i className="cropid cropid-magnifier" aria-hidden="true" />
                                           </a>
                                       </div>
                                       {/* List Item */}
                                       {addressContext.address.addresses.map((item,i) => {
                                           const {icon,name} = addressTypes(item.type);
                                           const ii = orderAsc ? ++i : (addressContext.address.total_address - i);
                                           console.log(item)
                                            return (
                                                <div className="list-item">
                                                    {/* Order */}
                                                    <div className="order"><span>#{ii}</span></div>
                                                    {/* Code */}
                                                    <div className="code" data-cropid-copy="mehmetev">{item.slug}</div>
                                                    {/* Detail */}
                                                    <div className="detail"><span>{icon}</span> {name}&nbsp;&nbsp;&nbsp;<i>{item.details?.stage_3?.details}</i></div>
                                                    {/* Dropdown */}
                                                    <div className="cropid-dropdown">
                                                        {/* Dropdown Button */}
                                                        <button type="button" className="cropid-dropdown-button" onClick={()=>{
                                                            setEditItem(item.id);
                                                        }} data-cropid-dropdown>
                                                            <i className="cropid cropid-dots" aria-hidden="true" />
                                                        </button>
                                                        {/* Dropdown Inner */}
                                                        <div className={"cropid-dropdown-inner "+(editItem==item.id ? 'cropid-active' : '')}>
                                                            {/* Item */}
                                                            <a href="#" onClick={(e)=>{
                                                                e.preventDefault();
                                                                history.push("/address/"+item.slug)
                                                            }} className="item">Düzenle</a>
                                                            <a href="#" onClick={(e)=>{
                                                                e.preventDefault();
                                                                addressContext.del(item.id)

                                                            }} className="item">Sil</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                       })}
                                       {addressContext.address.addresses.length== 0 ? <h5 style={{textAlign:"center"}}>Kayıtlı Adres Bulunamadı.</h5> : null}

                                       {/* List Item */}
                                   </div>
                               </div>
                           </div>
                       </div>
                   </div>
               </div>
           </section>

       </Layout>
    );
}

export default withRouter (AppHOC(Dashboard));
