import React, {useContext, useEffect, useState} from 'react';
import {post} from "../utils/http";
import storage from "../utils/storage";
import i18next from "i18next";
import ReactTooltip from "react-tooltip";
import {error, nameParser, params, redirectLogin, success} from "../utils/func";
import {deleteAddress, getAddress, getAddressBySlug, getCheckCode, saveAddress} from "../api/address";
import Schema from 'validate'
import {useHistory} from "react-router-dom";
import {ProfileContext} from "./ProfileContext";




export const AddressContext = React.createContext();


export function AddressProvider (props) {
    const profileContext = useContext(ProfileContext);
    const history = useHistory();
    const [active,setActive] = useState(null);
    const [address,setAddress] = useState({
        total_address:0,
        addresses:[]
    });
    const INITIAL_STATE = {
        active_page:1,
        current_page:1,
        stage_1:{
            code:params("code"),
            clone_code:params("code"),
            error:"",
            success:false,
            similar:false
        },
        stage_2:{
            firstname:"",
            lastname:"",
            phone:"",
            validations:[]
        },
        stage_3:{
            country:null,
            city:null,
            district:null,
            neighborhood:null,
            apartment: null,
            floor:null,
            rooms:null,
            details:null,
            type:"home",
            validations:[]
        },
        stage_3_1:{
            country:null,
            city:null,
            district:null,
            neighborhood:null,
            apartment: null,
            floor:null,
            rooms:null,
            details:null,
            type:"individual",
            idNumber:null,
            taxNumber: null,
            taxOffice: null,
            sameAddress:true,
            validations:[]
        },
        stage_4:{
            fullname_share:true,
            phone_share:true,
            submit:false
        },
    }
    const [data, setData] = useState(INITIAL_STATE);





    const dispatch = (value) => {
        setData({...data,...value})
    };

    const query = (value) => {
        const queryCheck = async () => {

            if(data.stage_1.code==""){
                dispatch({stage_1:{...data.stage_1,error:false,success:true,similar:false}});
                return null;
            }
            try{

                const res = await getCheckCode(data.stage_1.code);
                dispatch({stage_1:{...data.stage_1,error:false,success:true,similar:false}});
            }catch (e) {
                dispatch({stage_1:{...data.stage_1,error:"Bu kod kullanımda, lütfen başka bir kod seçiniz",success:false,similar: true}});
            }

        };

        queryCheck();
    };

    const validate = (stage) => {
        let schema = null;
        if(stage==2) {
            schema = new Schema({
                firstname: {
                    type: String,
                    required: true
                },
                lastname: {
                    type: String,
                    required: true
                },
                phone: {
                    type: String,
                    required: true
                }
        });
        }else if(stage==3){
             schema = new Schema({
                 country: {
                     type: Number,
                     required: true
                 },
                 city: {
                     type: Number,
                     required: true
                 },
                 district: {
                     type: Number,
                     required: true
                 },
                 neighborhood: {
                     type: Number,
                     required: true
                 },
                 apartment: {
                    type: String,
                    required: true
                },
                 floor: {
                    type: String,
                    required: true
                },
                 rooms: {
                    type: String,
                    required: true
                },
                 details: {
                     type: String,
                     required: true
                 },
                 type: {
                     type: String,
                     required: true
                 }
            });
        }else if(stage=="3_1"){
            let addedSchema = {
                sameAddress: {
                    type: Boolean
                },
                type: {
                    type: String
                }
            };
            if(data["stage_"+stage].type=="individual"){
                 addedSchema = {
                    ...addedSchema,
                   idNumber: {
                       type: String,
                       required: true
                   }
               }
            }else{
                  addedSchema = {
                    ...addedSchema,
                    taxOffice: {
                        type: String,
                        required: true
                    },
                    taxNumber: {
                        type: String,
                        required: true
                    }
                }
            }

            if(!data["stage_"+stage].sameAddress){
                addedSchema = {...addedSchema,
                    country: {
                        type: Number,
                        required: true
                    },
                    city: {
                        type: Number,
                        required: true
                    },
                    district: {
                        type: Number,
                        required: true
                    },
                    neighborhood: {
                        type: Number,
                        required: true
                    },
                    apartment: {
                        type: String,
                        required: true
                    },
                    floor: {
                        type: String,
                        required: true
                    },
                    rooms: {
                        type: String,
                        required: true
                    },
                    details: {
                        type: String,
                        required: true
                    }
                };
            }

            console.log("STAGE_BASLADI");
            schema = new Schema({
                ...addedSchema
            });

        }

        const errors = schema.validate(data["stage_"+stage]);
        dispatch({[["stage_"+stage]]:{...data["stage_"+stage],validations:errors.map((v) => v.path)}});


        if(errors.length===0){
            return true;
        }
    }

    const refresh= (data)=>{
        const refreshRequest = async (data) => {
            try{
                const res = await getAddress(data);
                setAddress(res);
            }catch (e) {
                error(e);
            }

        }

        refreshRequest(data);
    }

    const save = ()=>{
        const saveRequest = async () => {
            try{
                if(active==null){
                    const res = await saveAddress(data);
                }else{
                    const res = await saveAddress(data,active);
                }
                await refresh()
                await reset();
                await profileContext.dispatch();
                success("Kaydedildi");
                history.push("/");
            }catch (e) {
                error(e);
            }

        }
        dispatch({stage_4:{...data.stage_4,submit:true}});
        saveRequest();
    }

    const del = (id)=>{
        const delRequest = async (id) => {
            try{
                const res = await deleteAddress(id);
                await refresh()
                await profileContext.dispatch();
                success("Silindi");
            }catch (e) {
                error(e);
            }

        }

        delRequest(id);
    }

    const get = (slug,callback)=>{
        const getRequest = async (slug) => {
            try{
                const res = await getAddressBySlug(slug);
                setActive(slug);
                await dispatch({...res.details,active_page:2})
                //await dispatch({active_page:2})
                callback(res.details);
            }catch (e) {
                error(e);
            }

        }

        getRequest(slug,callback);
    }

    const reset = ()=>{
        setActive(null);
        profileContext.dispatch();
        dispatch(INITIAL_STATE);
    }

    useEffect (() => {

    }, []);

    return (
        <AddressContext.Provider value={{
            data,
            address,
            dispatch,
            query,
            validate,
            save,
            refresh,
            del,
            get,
            reset
        }}>
            {props.children}
        </AddressContext.Provider>
    )
}
