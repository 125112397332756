import {ProfileContext, ProfileProvider} from "../../contexts/ProfileContext";
import React, {useContext, useEffect, useState} from "react";


const Auth = ({children,sidebar=f=>f,title='Dashboard',sidebarBottom=f=>f,onSearch=f=>f}) => {

    const context = useContext(ProfileContext);
    const [openQuickMenu,setOpenQuickMenu] = useState("");
    const [openMenu,setOpenMenu] = useState(false);
    const [searchInput,setSearchInput] = useState("");

    return (
            <>
                <header id="site-header">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-4 col-lg-6 d-flex justify-content-center">
                                <a href="https://cropid.com" className="site-logo">
                                    <img src="/assets/images/logo.svg" alt="Cropid" />
                                </a>
                            </div>
                        </div>
                    </div>
                </header>
                {children}
            </>
    )
};

export default Auth;
