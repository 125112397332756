import React, {useContext, useEffect, useState} from 'react';
import AuthHOC from "../HOC/Auth";
import {Link, withRouter} from "react-router-dom";
import {useLogin} from "../../hooks/useAuth";
import i18next from "i18next";
import Auth from "../layouts/auth";
import Address from "../layouts/address";
import {AddressContext} from "../../contexts/AddressContext";
import AsyncSelect from 'react-select/async'
import Select from 'react-select/'
import {selectStyles} from "../../utils/func";
import {useAddress} from "../../hooks/useAddress";


const Stage3 = ({history,onlyForm=false}) => {

    const addressContext = useContext(AddressContext);
    const {countryList,cityList,districtList,neighborhoodList,
        setCountry,setCity,setDistrict,setNeighborhood,
        country,city,district,neighborhood} = useAddress();

    const [selectCountry,setSelectCountry] = useState(null);
    const [selectCity,setSelectCity] = useState(null);
    const [selectDistrict,setSelectDistrict] = useState(null);
    const [selectNeighborhood,setSelectNeighborhood] = useState(null);

    useEffect(()=>{
        let countryLabel = countryList.find((i)=>{
            if(i.value===addressContext.data.stage_3.country){
                return i.label;
            }
        });
        setSelectCountry(countryLabel);
        setCountry(countryLabel?.value);
    },[countryList]);

    useEffect(()=>{
        let cityLabel = cityList.find((i)=>{
            if(i.value===addressContext.data.stage_3.city){
                return i.label;
            }
        });
        console.log(cityList);
        setSelectCity(cityLabel);
        setCity(cityLabel?.value);
    },[cityList]);

    useEffect(()=>{
        let districtLabel = districtList.find((i)=>{
            if(i.value===addressContext.data.stage_3.district){
                return i.label;
            }
        });
        setSelectDistrict(districtLabel)
        setDistrict(districtLabel?.value);
    },[districtList]);

    useEffect(()=>{
        let neighborhoodLabel = neighborhoodList.find((i)=>{
            if(i.value===addressContext.data.stage_3.neighborhood){
                return i.label;
            }
        });
        setSelectNeighborhood(neighborhoodLabel)
        setNeighborhood(neighborhoodLabel?.value);
    },[neighborhoodList]);

   

    return (
        <>
            {/* WIZARD PAGE CONTENT SECTION BEGIN */}
            <section id="page" className="address-stage wizard-content">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className={onlyForm ? "col-lg-12" : "col-lg-7"}>
                            {/* Wizard Content */}
                            <div className="wizard-content-inner">
                                {/* Title */}
                                <div className={"title "+(onlyForm ? 'hidden' : null)}>
                                    <h6>3. Adım</h6>
                                    <h1>Adres Bilgilerini Oluştur</h1>
                                </div>
                                {/* Map
                                <div className="cropid-map">
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d96298.27723674898!2d28.97391737814025!3d41.04010527509366!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14caa7040068086b%3A0xe1ccfe98bc01b0d0!2zxLBzdGFuYnVs!5e0!3m2!1str!2str!4v1593844940454!5m2!1str!2str" frameBorder={0} style={{border: 0}} allowFullScreen aria-hidden="false" tabIndex={0} />
                                </div>
                                */}
                                {/* Address Form */}
                                <form className="address-form">
                                    {/* Form Group */}
                                    <div className="cropid-form-group flex two">
                                        {/* Select */}
                                        <div className={"cropid-select-group "+(addressContext.data.stage_3.validations?.includes("country") ? 'error' : '')}>
                                            <Select className={"react_select"} styles={selectStyles()} placeholder={"Ülke"} onChange={({value})=>{
                                                setCountry(value)
                                                addressContext.dispatch({stage_3:{...addressContext.data.stage_3,country:value}});
                                            }} value={selectCountry} options={countryList}/>
                                        </div>
                                        {/* Select */}
                                        <div className={"cropid-select-group "+(addressContext.data.stage_3.validations?.includes("city") ? 'error' : '')}>
                                            <Select className={"react_select"} styles={selectStyles()} placeholder={"Şehir"} options={cityList}  onChange={({value})=>{
                                                setCity(value);
                                                addressContext.dispatch({stage_3:{...addressContext.data.stage_3,city:value}});
                                            }} value={selectCity} />
                                        </div>
                                    </div>
                                    {/* Form Group */}
                                    <div className="cropid-form-group flex two">
                                        {/* Select */}
                                        <div className={"cropid-select-group "+(addressContext.data.stage_3.validations?.includes("district") ? 'error' : '')}>
                                            <Select className={"react_select"} styles={selectStyles()} placeholder={"İlçe"}  options={districtList}  onChange={({value})=>{
                                                setDistrict(value);
                                                addressContext.dispatch({stage_3:{...addressContext.data.stage_3,district:value}});
                                            }} value={selectDistrict}  />
                                        </div>
                                        {/* Select */}
                                        <div className={"cropid-select-group "+(addressContext.data.stage_3.validations?.includes("neighborhood") ? 'error' : '')}>
                                            <Select className={"react_select"} styles={selectStyles()} placeholder={"Mahalle"} options={neighborhoodList}  onChange={({value})=>{
                                                setNeighborhood(value);
                                                addressContext.dispatch({stage_3:{...addressContext.data.stage_3,neighborhood:value}});
                                            }} value={selectNeighborhood}/>
                                        </div>
                                    </div>
                                    {/* Form Group */}
                                    <div className="cropid-form-group flex three">
                                        {/* Input Group */}
                                        <div className={"cropid-input-group type-2 mobile-m0 "+(addressContext.data.stage_3.validations?.includes("apartment") ? 'error' : '')}>
                                            {/* Input */}
                                            <input id="inp1" type="text" onChange={(e)=>{
                                                addressContext.dispatch({stage_3:{...addressContext.data.stage_3,apartment:e.target.value}});
                                            }} value={addressContext.data.stage_3.apartment} className="cropid-input" placeholder="Bina No" />
                                            {/* Inner Icon */}
                                            <label htmlFor="inp1" className="inner--icon">
                                                <i className="cropid cropid-house" aria-hidden="true" />
                                            </label>
                                        </div>
                                        {/* Input Group */}
                                        <div className={"cropid-input-group type-2 mobile-m0 "+(addressContext.data.stage_3.validations?.includes("floor") ? 'error' : '')}>
                                            {/* Input */}
                                            <input inputMode={"numeric"} pattern="[0-9]*" id="inp2" type="number" className="cropid-input" onChange={(e)=>{
                                                addressContext.dispatch({stage_3:{...addressContext.data.stage_3,floor:e.target.value}});
                                            }} value={addressContext.data.stage_3.floor} placeholder="Kat" />
                                            {/* Inner Icon */}
                                            <label htmlFor="inp2" className="inner--icon">
                                                <i className="cropid cropid-step" aria-hidden="true" />
                                            </label>
                                        </div>
                                        {/* Input Group */}
                                        <div className={"cropid-input-group type-2 mobile-m0 "+(addressContext.data.stage_3.validations?.includes("rooms") ? 'error' : '')}>
                                            {/* Input */}
                                            <input inputMode={"numeric"} pattern="[0-9]*" id="inp3" type="number" className="cropid-input" onChange={(e)=>{
                                                addressContext.dispatch({stage_3:{...addressContext.data.stage_3,rooms:e.target.value}});
                                            }} value={addressContext.data.stage_3.rooms} placeholder="Daire No" />
                                            {/* Inner Icon */}
                                            <label htmlFor="inp3" className="inner--icon">
                                                <i className="cropid cropid-house" aria-hidden="true" />
                                            </label>
                                        </div>
                                    </div>
                                    {/* Form Group */}
                                    <div className="cropid-form-group flex two-r">
                                        {/* Select */}
                                        <div className="cropid-select-group not_selectize">
                                            <select onChange={(e)=>{
                                                addressContext.dispatch({stage_3:{...addressContext.data.stage_3,type:e.target.value}});
                                            }} value={addressContext.data.stage_3.type} className="cropid-select">
                                                <option value={"home"}>🏠 Ev Adresi</option>
                                                <option value={"office"}>💼 İş Adresi</option>
                                                <option value={"school"}>🎒 Okul Adresi</option>
                                            </select>
                                        </div>
                                        {/* Input */}
                                        <input id="inp2" type="text" className={"cropid-input type-2 "+(addressContext.data.stage_3.validations?.includes("details") ? 'error_border' : '')}  onChange={(e)=>{
                                            addressContext.dispatch({stage_3:{...addressContext.data.stage_3,details:e.target.value}});
                                        }} value={addressContext.data.stage_3.details} placeholder="Adres Tarifi (Sokak vb detaylar ekleyebilirsiniz.)" />
                                    </div>
                                </form>
                                {/* Cropid Sperator */}
                                <div className={"cropid-sperator "+(onlyForm ? 'hidden' : null)} />
                                {/* Content Footer */}
                                <div className={"content-footer "+(onlyForm ? 'hidden' : null)}>
                                    {/* Back Button */}
                                    <a href="" onClick={(e)=>{
                                        e.preventDefault();
                                        addressContext.dispatch({active_page:2});
                                    }} className="back-btn s">
                                        {/* Icon */}
                                        <i className="cropid cropid-arrow-left" />
                                        {/* Inner Text */}
                                        <span className="inner--text">Geri Dön</span>
                                    </a>
                                    {/* Continue Button */}
                                    <a href="#" onClick={(e)=>{
                                        e.preventDefault()
                                        if(addressContext.validate(3)) {
                                            addressContext.dispatch({active_page: 3.1, current_page: 3.1});
                                        }
                                    }} className="cropid-button type-1 with-icon">
                                        {/* Inner Text */}
                                        <span className="inner--text">Fatura Bilgileri</span>
                                        {/* Inner Icon */}
                                        <span className="inner--icon">
                <i className="cropid cropid-arrow-up-right" aria-hidden="true" />
              </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* WIZARD PAGE CONTENT SECTION END */}


        </>
    );
}

export default Stage3