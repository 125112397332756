import {ProfileContext, ProfileProvider} from "../../contexts/ProfileContext";
import React, {useContext, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {dateFormat} from "../../utils/func";
import moment from "moment";
import config from '../../config.json';

const Layout = ({children,sidebar=f=>f,title='Dashboard',sidebarBottom=f=>f,onSearch=f=>f}) => {

    const context = useContext(ProfileContext);
    const [openQuickMenu,setOpenQuickMenu] = useState("");
    const [openMenu,setOpenMenu] = useState(false);
    const [searchInput,setSearchInput] = useState("");

    return (
            <>
                {/* SITE HEADER BEGIN */}
                <header id="site-header">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-3 col-6">
                                {/* Site Logo */}
                                <a href={config.base_home_url} className="site-logo">
                                    <img src="/assets/images/logo.svg" alt="Cropid" />
                                </a>
                        </div>
                            <div className="col-lg-9 col-6 d-flex justify-content-end  account-header-right">
                                {/* Last Login */}
                                <div className="last-login">
                                    <span>son giriş</span>
                                    <p>{dateFormat(context.value.last_login==null ? context.value.created_at : context.value.last_login,"DD.MM.YYYY HH:mm")}</p>
                                </div>
                                {/* Account Button */}
                                {window.location.pathname.startsWith("/profile") ?
                                    <Link to="/" className="cropid-button icon-with-text ">
                                        {/* Inner Icon */}
                                        <span className="inner--icon">
            <i className="cropid cropid-user-thick" aria-hidden="true"/>
          </span>
                                        {/* Inner Text */}
                                        <span className="inner--text">
                                        Adreslerim
                                      </span>
                                    </Link>
                                    :
                                    <Link to="/profile" className="cropid-button icon-with-text">
                                        {/* Inner Icon */}
                                        <span className="inner--icon">
            <i className="cropid cropid-user" aria-hidden="true"/>
          </span>
                                        {/* Inner Text */}
                                        <span className="inner--text">
                                        Hesabım
                                      </span>
                                    </Link>
                                }
                            </div>
                        </div>
                    </div>
                </header>
                {/* SITE HEADER END */}

                {children}

                {/* SITE FOOTER BEGIN */}
                <footer id="site-footer">
                    <div className="container-fluid cropid-container">
                        {/* Footer Inner */}
                        <div className="footer-inner">
                            <div className="row">
                                <div className="col-lg-12">
                                    {/* Google */}
                                    <div className="google text-center">
                                        <span>Bilgilerinizin güvenliği</span>
                                        <img src="/assets/images/google.png" alt="Google" />
                                        <span>altyapısı ile korunma altındadır</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </footer>
                {/* SITE FOOTER END */}

            </>
    )
};

export default Layout;
