import React, {useState} from 'react';
import AuthHOC from "../HOC/Auth";
import {Link, withRouter} from "react-router-dom";
import {useRegister} from "../../hooks/useAuth";
import i18next from "i18next";
import Auth from "../layouts/auth";
import config from '../../config.json';
const Login = ({history}) => {
    document.title="Kayıt ol"
    const { onNameChange,onConfirmPasswordChange,onEmailChange,onPasswordChange,onSubmit,onTermsChange,validation } = useRegister();
    const [ firstname,checkName] = useState('');
    const [ email,setEmail] = useState('');
    const [ pass,setPass] = useState('');
    const [ passConfirm,setPassConfirm] = useState('');
    function checkFirstName(e){
        onNameChange(e);
        if(e.target.value.length > 0){
            checkName('input-texted');
        }else{
            checkName('');
        }
    }
    function checkEmail(e){
        onEmailChange(e);
        if(e.target.value.length > 0){
            setEmail('input-texted');
        }else{
            setEmail('');
        }
    }
    function checkPass(e){
        onPasswordChange(e);
        if(e.target.value.length > 0){
            console.log(e.target.value);
            setPass('input-texted');
        }else{ 
            setPass('');
        }
    }
    function checkPassConfirm(e){
        onConfirmPasswordChange(e);
        if(e.target.value.length > 0){
            setPassConfirm('input-texted');
        }else{
            setPassConfirm('');
        }
    }
   
    
   
    
    return (
        <Auth>
            {/* REGISTER SECTION BEGIN */}
            <section id="page" className="login-register">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-4 col-lg-6">
                            {/* Login Card */}
                            <div className="cropid-login-card">
                                {/* Card Header */}
                                <div className="cropid-card-header">
                                    <h1>Ücretsiz Kaydol</h1>
                                    <p>Cropid ile üyelik formlarıyla uğraşma bir kod ve şifre.</p>
                                </div>
                                {/* Card Content */}
                                <div className="cropid-card-content">
                                    <form onSubmit={(e)=>{
                                        e.preventDefault();
                                        onSubmit();
                                    }} role="form">
                                        {/* Input Group */}
                                        <div className={"cropid-input-group type-2 "+(validation?.name ? "error" : "")}>
                                            {/* Input */}
                                            <input type="text" onChange={(e)=>{
                                                checkFirstName(e)
                                            }}   className={`cropid-input ${firstname}`}  placeholder="Adınız Soyadınız" />
                                            {/* Inner Icon */}
                                            <label htmlFor="text" className="inner--icon">
                                                <i className="cropid cropid-user" aria-hidden="true" />
                                            </label>
                                            {/* Error Message */}
                                            <span className="error-message">{validation?.name}</span>
                                        </div>
                                        <div id="email" className={"cropid-input-group type-2 "+(validation?.email ? "error" : "")}>
                                            {/* Input */}
                                            <input type="email" onChange={(e)=>{
                                                checkEmail(e)
                                            }}  className={`cropid-input ${email}`}  placeholder="E-mail Adresiniz" />
                                            {/* Inner Icon */}
                                            <label htmlFor="email" className="inner--icon">
                                                <i className="cropid cropid-envelope" aria-hidden="true" />
                                            </label>
                                            {/* Error Message */}
                                            <span className="error-message">{validation?.email}</span>
                                        </div>
                                        {/* Input Group */}
                                        <div className={"cropid-input-group type-2 "+(validation?.password ? "error" : "")}>
                                            {/* Input */}
                                            <input type="password" onChange={(e)=>{
                                                checkPass(e)
                                            }} className={`cropid-input ${pass}`} placeholder="Şifreniz" />
                                            {/* Inner Icon */}
                                            <label htmlFor="pwd" className="inner--icon">
                                                <i className="cropid cropid-lock" aria-hidden="true" />
                                            </label>
                                            {/* Error Message */}
                                            <span className="error-message">{validation?.password}</span>
                                        </div>
                                        {/* Input Group */}
                                        <div className={"cropid-input-group type-2 "+(validation?.confirmPassword ? "error" : "")}>
                                            {/* Input */}
                                            <input id="pwdCnfrm" onChange={(e)=>{
                                                checkPassConfirm(e)
                                            }}  type="password" className={`cropid-input ${passConfirm}`}  placeholder="Şifreniz Tekrar" />
                                            {/* Inner Icon */}
                                            <label htmlFor="pwdCnfrm" className="inner--icon">
                                                <i className="cropid cropid-lock" aria-hidden="true" />
                                            </label>
                                            {/* Error Message */}
                                            <span className="error-message">{validation?.confirmPassword}</span>
                                        </div>
                                        {/* Checkbox Group */}
                                        <div className={"cropid-checkbox-group type-1 "+(validation?.terms ? "error" : "")}>
                                            {/* Input */}
                                            <input type="checkbox" onChange={(e)=>{
                                                if(e.target.checked){
                                                    onTermsChange("yes")
                                                }else{
                                                    onTermsChange("");
                                                }
                                            }} id="remember" className="cropid-checkbox" />
                                            {/* Label */}
                                            <label htmlFor="remember">
                                                <span><a href={config.accept_terms_url} target="_blank">Kullanım Şartlarını</a> okudum, kabul ediyorum.</span>
                                            </label>
                                            <span className="error-message">{validation?.terms}</span>
                                        </div>
                                        {/* Button */}
                                        <button type="submit" className="cropid-button type-2">Kayıt Ol</button>
                                    </form>
                                </div>
                            </div>
                            {/* Register */}
                            <div className="register">
                                <p>Kaydolmaya ihtiyacım yok, zaten bir hesabım var!</p>
                                <Link to="/auth/login" className="cropid-button type-1">Giriş Yap</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* REGISTER SECTION END */}

        </Auth>

    );
}

export default withRouter (AuthHOC(Login));
