import {del, get, post,put} from "../utils/http";

export const getRegionList = async (parent,child) => {
    try {
        return await get('/region?id='+parent+'&child='+child);
    }catch (e) {
        throw e.response.data.error;
    }
}


export const getCheckCode = async (code) => {
    return await post('/address/validate',{q:code});
}


export const saveAddress = async (data,slug=null) => {
    if(slug==null){
        return await post('/address',data);
    }else{
        return await put('/address/'+slug,data);
    }
}

export const getAddress = async (data) => {
    return await get('/address?'+(new URLSearchParams(data).toString()));
}

export const deleteAddress = async (id) => {
    return await del('/address/'+id);
}

export const getAddressBySlug = async (slug) => {
    return await get('/address/'+slug);
}


