import React, {useContext, useEffect, useState} from 'react';
import AuthHOC from "../HOC/Auth";
import {Link, withRouter} from "react-router-dom";
import {useLogin} from "../../hooks/useAuth";
import i18next from "i18next";
import Auth from "../layouts/auth";
import Address from "../layouts/address";
import {AddressContext} from "../../contexts/AddressContext";
import {ProfileContext} from "../../contexts/ProfileContext";
import {nameParser} from "../../utils/func";
import 'react-phone-number-input/style.css'
import Input from 'react-phone-number-input/input'
import {isValidPhoneNumber} from 'react-phone-number-input'

const Stage2 = ({history}) => {
    const addressContext = useContext(AddressContext);
    const profileContext = useContext(ProfileContext);
    useEffect(()=>{
        setTimeout(()=>{


        const name = nameParser(profileContext.value.name);
        addressContext.dispatch({stage_2:{...addressContext.data.stage_2,firstname:name.firstName,lastname:name.lastName,phone:profileContext.value?.phone}});
        },10);
    },[profileContext.value]);
    return (
        <>
            {/* WIZARD PAGE CONTENT SECTION BEGIN */}
            <section id="page" className="wizard-content">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-5 col-lg-6">
                            {/* Wizard Content */}
                            <div className="wizard-content-inner">
                                {/* Title */}
                                <div className="title">
                                    <h6>2. Adım</h6>
                                    <h1>Giriş Bilgierini Oluştur</h1>
                                </div>
                                {/* Info */}
                                <div className="info">
                                    <p>Kolay hatırlayacağınız kodu belirleyin ve hemen.</p>
                                </div>
                                {/* Login Info Form */}
                                <form className="login-info-form">
                                    {/* Input Group */}
                                    <div className={"cropid-input-group type-2 "+(addressContext.data.stage_2.validations?.includes("firstname") ? 'error' : '')}>
                                        {/* Input */}
                                        <input onChange={(e)=>{
                                            addressContext.dispatch({stage_2:{...addressContext.data.stage_2,firstname:e.target.value}});
                                        }}  type="text" value={addressContext.data.stage_2.firstname} className="cropid-input" placeholder="Adınız" />
                                        {/* Inner Icon */}
                                        <label htmlFor="name" className="inner--icon">
                                            <i className="cropid cropid-user" aria-hidden="true" />
                                        </label>
                                        {/* Error Message */}
                                        <span className="error-message">Lütfen bilgileri kontrol ediniz.</span>
                                    </div>
                                    {/* Input Group */}
                                    <div className={"cropid-input-group type-2 "+(addressContext.data.stage_2.validations?.includes("lastname") ? 'error' : '')}>
                                        {/* Input */}
                                        <input  onChange={(e)=>{
                                            addressContext.dispatch({stage_2:{...addressContext.data.stage_2,lastname:e.target.value}});
                                        }}   type="text"  value={addressContext.data.stage_2.lastname} className="cropid-input" placeholder="Soyadınız" />
                                        {/* Inner Icon */}
                                        <label htmlFor="surname" className="inner--icon">
                                            <i className="cropid cropid-user" aria-hidden="true" />
                                        </label>
                                        {/* Error Message */}
                                        <span className="error-message">Lütfen bilgileri kontrol ediniz.</span>
                                    </div>
                                    {/* Input Group */}
                                    <div className={"cropid-input-group type-2 "+(addressContext.data.stage_2.validations?.includes("phone") ? 'error' : '')}>
                                        {/* Input */}
                                        <input type="text" className="cropid-input" onChange={(e)=>{
                                                addressContext.dispatch({stage_2:{...addressContext.data.stage_2,phone:e.target.value}});
                                        }} maxLength={12} value={addressContext.data.stage_2.phone} placeholder="GSM Numaranız" />
                                        {/* Inner Icon */}
                                        <label htmlFor="phone" className="inner--icon">
                                            <i className="cropid cropid-phone" aria-hidden="true" />
                                        </label>
                                        {/* Error Message */}
                                        <span className="error-message">Lütfen bilgileri kontrol ediniz.</span>
                                    </div>
                                </form>
                                {/* Cropid Sperator */}
                                <div className="cropid-sperator" />
                                {/* Content Footer */}
                                <div className="content-footer">
                                    {/* Back Button */}
                                    <a href="" onClick={(e)=>{
                                        e.preventDefault();
                                        addressContext.dispatch({active_page:1});

                                    }} className="back-btn s">
                                        {/* Icon */}
                                        <i className="cropid cropid-arrow-left" />
                                        {/* Inner Text */}
                                        <span className="inner--text">Kod Oluştur</span>
                                    </a>
                                    {/* Continue Button */}
                                    <div onClick={()=>{
                                        if(addressContext.validate(2)) {
                                            addressContext.dispatch({active_page: 3, current_page: 3});
                                        }
                                    }} className="cropid-button type-1 with-icon">
                                        {/* Inner Text */}
                                        <span className="inner--text">Devam Et</span>
                                        {/* Inner Icon */}
                                        <span className="inner--icon">
                <i className="cropid cropid-arrow-up-right" aria-hidden="true" />
              </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* WIZARD PAGE CONTENT SECTION END */}


        </>
    );
}

export default Stage2